.inputFieldHead {
    color: #121E2C;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
}

.dropdown {
    display: inline-block;
    position: relative;
}

.btn-picker {
    display: flex;
    height: 48px;
    padding: 15px 16px;
    justify-content: center;
    align-items: flex-end;
    gap: 16px;
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid var(--hover, #CACED7) !important;
}

.container_div {
    padding: 30px;
    margin-top: 10px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 2px 16px 40px 0px rgba(112, 144, 176, 0.14);
}

.rdImpactdGroup {
    margin-top: 8px;
}

.rdImpactdGroup label {
    margin-left: 10px;
    margin-right: 10px;
    color: #50596B;
}

.btn-add-data {
    display: inline-flex;
    margin-top: 30px;
    border-radius: 6px;
    border: 1px solid #E3E5E9;
    color: #50596B;
    font-weight: 500;
    background: none !important;
    max-width: 100%;
    /* width: 188px; */
    height: 50px;
    padding: 20px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.mt-30 {
    margin-top: 30px !important;
}

.mr-15 {
    margin-right: 15px !important;
}

.mr-40 {
    margin-right: 40px !important;
}

.in-blck {
    display: inline-block;
}

.partsLabel {
    color: #50596B;
    font-size: 16px;
    font-weight: 500;
    margin-left: 15px;
    margin-right: 15px;
}

.incLabel {
    color: #121E2C;
    font-size: 20px;
    font-weight: 600;
}

.containerHeading {
    margin-top: 30px;
    color: #121E2C;
    font-size: 20px;
    font-weight: 600;
}

.css-1pduc5x-MuiStack-root>.MuiTextField-root {
    min-width: 100% !important;
    overflow: hidden;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    margin-top: -5px;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    height: 45px;
}

.css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background-color: #07AC2E !important;
}

.css-1beqopj-MuiPickersYear-yearButton.Mui-selected {
    background-color: #07AC2E !important;
}

.an-simple-select__option.an-simple-select__option--is-selected {
    background-color: white;
    color: #07AC2E;
}

.an-simple-select__option:hover {
    background-color: white;
    color: #07AC2E !important;
}

.css-13cymwt-control {
    height: 45px;
}

.mt-16 {
    margin-top: 16px !important;
}

.form-control {
    /* height: 45px; */
    height: 38px;
}

/* input[type='radio'] {
    width: 20px;
    height: 20px;
    border: 1px solid #07AC2E;
}

input[type='radio']:before {
    content:'';
    display:block;
    width:60%;
    height:60%;
    margin: 20% auto; 
    border-radius:50%;    
}

input[type='radio']:checked:before {
    background:#07AC2E;
} */

input[type="checkbox"] {
    accent-color: #07AC2E;
    transform: scale(1.4);
}

.addContractorModal {
        height: 220px !important;
        max-width: 900px !important;
        top: 35%;
        left: 31%;
        transform: translate(-50%, -50%) !important;
}

.verifyOtpModal {
    height: 400px !important;
    max-width: 600px !important;
    top: 35%;
    left: 24%;
    transform: translate(-50%, -50%) !important;
}

.uploadModal {
    height: 220px !important;
    max-width: 600px !important;
    top: 25%;
    left: 24%;
    transform: translate(-50%, -50%) !important; 
}

.listModal {
    max-width: 1000px !important;
    height: 470px;
    /* top: 35%;
    left: 24%;
    transform: translate(-50%, -50%) !important; */
}

.reportConfrmModal {
    height: 220px !important;
    max-width: 600px !important;
    top: 35%;
    left: 24%;
    transform: translate(-50%, -50%) !important;
}

.modulesModal {
    height: 220px !important;
    max-width: 725px !important;
    top: 35%;
    left: 28%;
    transform: translate(-50%, -50%) !important;
}

.sideOnRight {
    justify-content: end !important;
    border: none !important;
}

.reportText {
    color: #121E2C;
    text-align: center;
    font-size: 18px;
    font-weight: 450;
}

.middle {
    justify-content: center !important;
}

.btn-no {
    width: 188px;
    height: 45px;
    border-radius: 6px;
    border: 1px solid #E3E5E9;
}

.btn-yes {
    width: 188px;
    height: 45px;
    border-radius: 6px;
    background: #07AC2E;
    color: #fff;
    border: none;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    border-radius: 6px;
    border: 1px solid #E3E5E9;
    opacity: 0.4000000059604645;
}

.mt-10 {
    margin-top: 10px !important;
}

.containerJobTitle {
    margin-top: 10px;
    padding-bottom: 10px;
    border-radius: 6px;
    border-top: 1px solid var(--outline, #F0F0F0);
    background: #FAFAFA;
}

#RFS-StepButton.active span {
    background: #ffffff !important;
}

.alert-box {
    color: #121E2C;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
}

input[type="radio"] {
    accent-color: #07AC2E;
}

input[type='radio'] {
    /* width: 24px;
height: 24px;
vertical-align: middle; */
    transform: scale(1.2);
}

.btn-add-incident {
    display: inline-flex;
    height: 50px;
    padding: 15px 20px;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #E3E5E9;
    color: #50596B;
    text-align: center;
    margin-top: 30px;
    background: none;
}

.btn-cancel {
    display: inline-flex;
    height: 50px;
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 6px;
    color: #50596B;
    font-size: 16px;
    font-weight: 500;
    background: none;
}

.btn-event-incident {
    display: inline-flex;
    width: 188px;
    height: 50px;
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: #07AC2E;
    color: #FFF;
    font-size: 16px;
    border: 0;
}

.btn-add-incident:disabled {
    opacity: 0.4;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon {
    color: #07AC2E !important;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: #F8F8FA !important;
    border: 2px solid #07AC2E !important;
}

.ant-steps {
    width: 35% !important;
    font-family: 'Montserrat', sans-serif !important;
}

.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: #121E2C;
    font-size: 16px;
    font-weight: 500;
}

.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: #50596B;
    font-size: 16px;
    font-weight: 500;
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: #121E2C;
    font-size: 16px;
    font-weight: 500;
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    border-radius: 100px;
    background: #DFDFE1;
    width: 40px;
    height: 2px;
}

.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    border-radius: 100px;
    background: #DFDFE1;
    width: 40px;
    height: 2px;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #07AC2E;
}

.anticon svg {
    display: flex;
    color: #ffffff;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #F8F8FA;
    border: 1px solid #DFDFE1;
}

.containerHeading-complaint {
    margin-top: 1.8rem;
    color: #121E2C;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
}