.ant-steps {
    width: 42% !important;
}

/* Media query for screens with a maximum width of 1300px */
@media screen and (max-width: 1400px) {
    .ant-steps {
        width: 54% !important; /* Adjustment of width as needed for smaller screens */
    }
}

@media screen and (min-width: 1400px) and (max-width: 1299px) {
    .ant-steps {
        width: 47% !important; /* Adjustment of width as needed for smaller screens */
    }
}

/* Media query for screens with a minimum width of 1300px and a maximum width of 768px */
@media screen and (min-width: 768px) and (max-width: 1300px) {
    .ant-steps {
        width: 50% !important; /* Adjustment of width as needed for smaller screens */
    }
}

item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: #121E2C;
    font-size: 15.5px !important;
    font-weight: 500;
}