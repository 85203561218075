.width-455 {
    width: 455px;
}

.opacity-6 {
    opacity: 0.6;
}

.state-filter {
    display: flex;
    padding: 11px 12px 13px 8px;
    border-radius: 6px;
    border: 1px solid var(--hover, #CACED7);
}

.state-filter .dropdown-toggle {
    font-size: 16px;
    background: none;
}