.form-check-input:checked {
    background-color: #07AC2E;
}

.custom-checkbox .form-check-label {
    color: #50596B;
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
}

#dropdown-basic:hover {
    background-color: #ffffff;
}