.selectedSubModule {
    font-weight: 600;
    font-size: 22px;
    line-height: 26.82px;
    color: #121E2C;
}

.selected-filter-color {
    color: #07AC2E !important;
    /* cursor: pointer; */
  }

  /* Override Bootstrap button styles */
.btn.bg-gray {
    border: none;
  }
  
  /* Override Bootstrap dropdown menu styles */
  .dropdown-menu.bg-gray {
    border: none;
    box-shadow: none;
  }