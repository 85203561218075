.popover {
    max-width: 50% !important;
    padding: 30px !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 2px 16px 40px 0px rgba(112, 144, 176, 0.14), 2px 16px 40px 0px rgba(0, 0, 0, 0.20);
}

.bs-popover-end>.popover-arrow {
    display: none
}

.heading-text-white {
    color: #FFFFFF;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
}

.heading-text-black {
    color: #121E2C;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
}

.sub-heading-text {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    font-family: 'Montserrat', sans-serif;
}

.hr-border {
    color: #FFFFFF;
}

/* tab container css */
.tab-heading-font {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    font-family: 'Montserrat', sans-serif;
}

.tab-sub-heading-font {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    font-family: 'Montserrat', sans-serif;
}

.tab-from-to-heading-font {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0px;
    font-family: 'Montserrat', sans-serif;
}

.padding-6 {
    padding-top: 6px;
}

.padding-12-15 {
    padding: 12px 15px;
}

.margin-5-4 {
    margin: 5px 4px;
}

.popuuu {
    padding: 20px !important;
}

.light-blue {
    color: #50569B;
}

.customize-btn {
    color: #50596B;
    font-size: 14px;
    border: 1px solid #E3E5E9;
    height: 34px;
    border-radius: 6px;
    background-color: #ffffff;
}