.onboard_btn {
  /* max-width: 100%; */
  /* width: 164px; */
  height: 50px;
  padding: 12px 20px;
  justify-content: center;
  border-radius: 6px;
  background: #07AC2E;
  font-size: 16px;
  color: #ffffff;
}

.set-onbBtn-wd {
  width: 177px !important;
}