.alert-class {
    position: fixed;
    left: 50%;
    top: 1%;
    transform: translateX(-50%);
    z-index: 9999;
}

.outer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.inner {
    /* display: inline-block; */
}