.onboarding-cont {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    /* background: #f8f8fa; */
}

.welcomeCont {
    padding: 150px;
}

.auth_form_container {
    width: 480px;
    height: 500px;
    background: #fff;
    border-radius: 12px;
    overflow: hidden;
    padding: 40px 55px 33px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 2px 16px 40px 0px rgba(112, 144, 176, 0.24);
}

.title_text {
    color: #121E2C;
    font-size: 22px;
    font-weight: 500;
}

.content {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
    /* background-image: url('../../assets/images/bg-auth.svg'); */
    background-color: #102947;
}

.content::before {
    content: '';
    position: absolute;
    height: 253px;
    width: 483px;
    top: 0px;
    right: 0px;
    background-image: url('../../assets/images/top-logo.svg');
}

.content::after {
    content: '';
    position: absolute;
    height: 375px;
    width: 330px;
    bottom: 0px;
    left: 0px;
    background-image: url('../../assets/images/bottom-logo.svg');
}

.heading_text {
    color: #ffffff;
}

.litico_main_icon {
    width: 200px;
}

.forgot_passwrd_txt {
    color: #121E2C;
    font-size: 16px;
    font-weight: 500;
}

.click_here {
    color: #07AC2E;
    font-size: 16px;
    font-weight: 500;
    text-decoration-line: underline;
}

.passFld {
    position: relative;
}

.passShowIcon {
    position: absolute;
    right: 0.4rem;
    bottom: 0.7rem;
}

.emailShowIcon {
    position: absolute;
    right: 0.4rem;
    bottom: 0.45rem;
}

.btn-auth-action {
    display: flex;
    width: 370px;
    height: 50px;
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #07AC2E;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
}

.auth_modal_heading {
    color: #000;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
}

.auth_modal_descrip {
    color: #50596B;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    margin: auto;
    width: 402px;
}

.auth_modal_header {
    /* display: block; */
}

.auth_modal_size {
    width: 640px !important;
    height: 423px !important;
}

.auth_modal_footer {
    justify-content: center !important;
}

.resend {
    color: #121E2C;
    font-size: 16px;
    font-weight: 600;
}

.timer {
    color: #07AC2E;
    font-size: 14px;
    font-weight: 600;
}

.otpBox {
    width: 60px !important;
    height: 48px;
    margin-right: 10px;
    border-radius: 6px;
    border: 1px solid #CACED7;
}

.otpBoxContainer {
    display: flex;
    justify-content: center;
}

.ml-6 {
    margin-left: 5rem;
}

.ml-7 {
    margin-left: 7rem;;
}

.ml-11 {
    margin-left: 11rem;
}

.ml-27 {
    margin-left: 27px;
}

.ml-6- {
    margin-left: 6px !important;
}

.ml-19 {
    margin-left: 19px;
}

.ml-36 {
    margin-left: 36px;
}

.wd-v-p {
    width: 422px !important;
}

.input-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}

.modal-body-content {
    margin: auto;
    /* width: 74%; */
    width: 417px;
}

.forgot-modal-footer {
    justify-content: center !important;
    margin: auto;
    text-align: center;
}

.auth-modal-size {
    width: 640px !important;
    height: 423px !important;
}

.align-center {
    text-align: center;
}

.btn-login-light {
    display: flex;
    width: 370px;
    height: 50px;
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #FFF;
    color: #50596B;
    font-size: 16px;
    font-weight: 500;
    border: 0.1px solid black;
}

.back-to-login {
    color: #50596B;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 0px;
}

.disable-resend {
    color: #A8A8A8;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    cursor: no-drop;
}

.notifier-added-style {
    bottom: -5%;
    transform: translate(66%, 79%) !important;
}

.notifier-added-styles-for-otp-modal {
    bottom: -17%;
    transform: translate(26%, 79%) !important;
}

.main-page-notifier {
    left: 25%;
}

.change-height {
    height: 400px !important;
    max-width: 600px !important;
    top: 35%;
    left: 24%;
    transform: translate(-50%, -50%) !important;
}