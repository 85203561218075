.container {
    background-color: #fbfbfb;
  }
  
  /* graph container */
  .graph-container {
    width: 90%;
    background-color: rgba(255, 255, 255, 1);
    margin: auto;
    border-radius: 10px;
    margin-top: 2rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding: 20px 30px;
  }

   /* graph container */
   .graph-container-drag {
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    margin: auto;
    border-radius: 10px;
    margin-top: 2rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding: 20px 30px;
  }

  /* graph container */
  .horizontal-graph-container-drag {
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    margin-top: 2rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding: 5px 10px;
  }

   /* graph container */
   .pie-graph-container-drag {
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    margin-top: 2rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  /* header tab container */
  .header-tab-container {
    color: rgba(18, 30, 44, 1);
    font-weight: 600;
    line-height: 26.82px;
    font-size: 22px;
  }

  /* tooltip container classes */
.tooltip > div.tooltip-inner {
    background-color: white !important;
    color: black !important;
  }
  
  .tooltip.show {
    opacity: 1 !important;
  }
  
  .tooltip > div.arrow {
    display: none;
  }
  
  .tooltip .tooltip-arrow {
    display: none !important;
  }
  
  .tooltip > .tooltip-inner {
    max-width: 50% !important;
  }
  
  /* heading font style */
  .heading-font {
    font-size: 22px;
    font-weight: 600;
    line-height: 26.82px;
    color: rgba(18, 30, 44, 1);
  }
  
  /* graph heading style */
  .graph-heading {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    color: rgba(18, 30, 44, 1);
  }
  
  /* pop up heading style */
  .pop-up-heading {
    font-weight: 400;
    font-size: 20px;
    line-height: 24.38px;
    color: rgba(18, 30, 44, 1);
  }
  
  /* pop up heading style */
  .pop-up-body-heading {
    font-weight: 500;
    font-size: 18px;
    line-height: 21.94px;
    color: rgba(18, 30, 44, 1);
  }
  
  /* pop up body text styling */
 
  
  .partial-width-graph-container {
    margin: auto;
    border-radius: 10px;
    margin: 2rem 0rem;
    padding: 0px;
  }
  
  /* horizontal graph container styling */
  .horizontal-container {
    display: flex; 
    justify-content: space-evenly; 
  }
  
  /* horizontal graph content styling */
  .horizontal-graph-component {
    padding: 7px;
    border: 1px solid #ccc;
    width: 46%;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
  }

  .partial-horizontal-graph-component {
    padding: 10px;
    border: 1px solid #ccc;
    width: 45%;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
  }
  
  /* horizontal graph height styling */
  .containerStyle {
    height: 18rem;
    position: relative;
  }

  .margin-right-15 {
    margin-right: 15px;
  }

  .legend-title li{
    display: inline;
    padding-right: 1rem;
  }
  

  .box {
    margin-right: 5px;
  }
  
  .control-shade {
    background-color: #11C5BE;
  }

  .moderate-shade {
    background-color: #7275B3;
  }

  .attention-shade {
    background-color: #EEAA5C;
  }

  .legend-title {
    padding: 0px;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
  }

  .legend-heading {
    /* margin-left: 30px; */
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
  }

  .height-185 {
    height: 18.5rem;
  }

  .global-filter-button-margin {
    margin-top: 0px;
    margin-left: 70px;
  }
  
.btn-select-all {
width: 124px;
height: 40px;
padding: 8px;
border-radius: 6px;
border: 1px solid #E3E5E9;
color: #50596B;
font-size: 16px;
font-weight: 400;
background-color: #ffffff;
}

.bottom-label {
  padding: 10px;
}
