.listHead {
    font-size: 14px;
    font-weight: 400;
}

.listData {
    font-size: 14px;
    font-weight: bold; 
}

.headHeight {
    background-color: #F0F0F0;
    height: 40px;
    border-radius: 10px;
    align-items: center
}

.agency {
    color: #121E2C;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
}

.active-agency {
    color: #121E2C;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #07AC2E;
}

.inside_head_pad {
        padding-top: 29px;
        padding-bottom: 0px;
}

.w-900 {
    width: 900px;
}

.wider-td {
    width: 100px;
  }