body {
  font-family: 'Montserrat', sans-serif;
}

.cursor {
  cursor: pointer;
}

.defaultImgSize {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.fullWidth {
  width: 100%;
}

.fullWidthAntPicker {
  width: 292px;
}

.mr-2 {
  margin-right: 1rem;
}

.ant-picker-input input::placeholder {
  color: #212529 !important;
  font-size: 16px !important;
  font-family: 'Montserrat', sans-serif !important;
}

:where(.css-dev-only-do-not-override-1w4v3hc).ant-picker .ant-picker-input>input:placeholder-shown {
  margin-top: 5px !important;
}

.progress-text-container {
  margin: 100px 200px;
}

/* pie graph content styling */
.pie-graph-component {
  padding: 10px;
  border: 1px solid #ccc;
  width: 100%;
  background-color: rgba(43, 79, 120, 1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  padding-bottom: 10px
}

.body-part-item {
  margin-top: 2px;
  border: 0.1px solid white;
  border-radius: 5px;
  padding: 0px 10px;
  list-style-type: none;
  color: #FFFFFF;
  font-size: 13px;
}

.pie-graph-background-card {
  background-color: rgba(43, 79, 120, 1);
  padding: 15px;
}

.dot {
  height: 8px;
  width: 8px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

hr {
  clear: both;
  display: block;
  width: 100%;
  background-color: rgba(0, 0, 0, 1);
  height: 1px;
}

.no_margin {
  margin-top: 0px !important;
}

.ant-modal-title {
  color: #121E2C !important;
  font-family: 'Montserrat';
  font-weight: 400 !important;
}

.modal-dialog {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.man-hours-btn {
  max-width: 100%;
  height: 50px;
  padding: 12px 20px;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid #E3E5E9;
  background: linear-gradient(#2B4F78, #0B325F);
  font-size: 16px;
  color: #E3E5E9;
  margin-right: 25px;
}

.report_customize_btn {
  max-width: 100%;
  height: 50px;
  padding: 12px 20px;
  justify-content: center;
  border-radius: 6px;
  background: #07AC2E;
  font-size: 16px;
  color: #ffffff;
}

.searchWrapper .chip {
  background: #07AC2E;
}

.highlightOption {
  background: #07AC2E;
}

.multiSelectContainer li:hover:hover {
  background: #07AC2E;
}

.highlightOption {
  background: #07AC2E !important;
}

.error {
  color: #DF6574;
  font-size: 13px;
  font-weight: 600;
}

.sub-text {
  color: '#50596B';
  font-family: 'Montserrat';
  font-weight: 300;
}

.margin-top-36 {
  margin-top: 36px;
}

.bg-white {
  background-color: white;
}

.background-F8F8FA {
  background-color: #F8F8FA;
}

.font-9 {
  font-size: 9px;
}

.font-185-rem {
  font-size: 18.5rem;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.padding-bottom-0 {
  padding-bottom: 0px !important;
}

.padding-top-0 {
  padding-top: 0px !important;
  margin-top: -2rem;
}

.CircularProgressbar {
  width: 46px !important;
  height: 46px !important;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #11C5BE !important;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #000000 !important;
  font-weight: bolder;
}

.boxWidth {
  width: 498px;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
}

.ml-30 {
  margin-left: 32px !important;
}

.percentProcess {
  border: 1px solid #F3F3F3;
}

.imageName {
  color: #50596B;
  font-size: 16px;
  font-weight: 600;
  word-wrap: break-word;
}

.imageSize {
  color: #50596B;
  font-size: 14px;
  font-weight: 400;
}

.bgPercentProcessColor {
  padding: 20px;
  background-color: #F3F3F3;
}

.imageUpload {
  border: 1px solid #11C5BE;
}

.bgUploadCompleted {
  text-align: center;
  padding: 27px;
  background-color: #11C5BE1A;
}

.contentCenter {
  justify-content: center;
}

.alertUpload {
  border: 1px solid #DF6574;
}

.bgAlertCompleted {
  text-align: center;
  padding: 27px;
  background-color: #DF65741A;
}

.dropdown-menu>a:hover {
  background-image: none !important;
  background-color: #ffffff !important;
  color: #000000 !important;
}



.createDateRangePicker .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  background-color: #07AC2E !important;
  border-radius: 40% !important;
}

.createDateRangePicker .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #07AC2E !important;
  border-radius: 40% !important;
}

.createDateRangePicker .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #07AC2E !important;
}

.createDateRangePicker .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.createDateRangePicker .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.createDateRangePicker .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.createDateRangePicker .ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #07AC2E !important;
}

.createDateRangePicker .ant-btn-primary {
  background-color: #07AC2E !important;
  border-color: #07AC2E !important;
}

:where(.css-dev-only-do-not-override-nnuwmp).ant-picker,
:where(.css-nnuwmp).ant-picker {
  padding: 4px 11px 4px 37px !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.css-12a83d4-MultiValueRemove {
  background-color: #DFDFE1 !important;
  color: #969BA6 !important;
}

.css-12a83d4-MultiValueRemove:hover {
  background-color: #DFDFE1 !important;
  color: #969BA6 !important;
}

.css-13cymwt-control {
  height: auto !important;
}

.bg-white {
  background-color: #ffffff;
}

.view-icon {
  /* height: 2rem; */
  width: 1.15rem;
}

.react-datepicker__day--keyboard-selected {
  background-color: #07AC2E !important;
  color: white !important;
}

.react-datepicker__day--selected {
  background-color: #07AC2E !important;
  color: white !important;
}

.content-center {
  text-align: center !important;
  justify-content: center !important;
}

.margin-container {
  margin: 0px -12px !important;
}

.no-data-graph-container {
  /* width: 94%; */
  background-color: rgba(255, 255, 255, 1);
  margin: auto;
  border-radius: 10px;
  margin-top: 2rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 20px 30px;
  text-align: center;

  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  font-family: 'Montserrat', sans-serif;
}

.text-alert {
  color: #FF4726 !important;
}

.text-warn {
  color: #D08228 !important;
}

.text-success {
  color: #00AD71 !important;
}

.text-regular {
  color: #50596B !important;
}

.margin-left-0 {
  margin-left: 0px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.mr-30 {
  position: fixed !important;
  margin-right: 30px;
}

.spill-boxWidth {
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
}

.success-btn {
  display: inline-flex !important;
  /* width: 147px; */
  margin-left: 20px;
  height: 35px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #07AC2E;
  color: #FFF;
  font-size: 14.5px;
  /* font-weight: 600; */
  border: none;
  padding: 0px 15px;
}

.move-right {
  justify-content: end !important;
}

.agency-div {
  padding: 0px 15px;
}

.btn-class-man-hours {
  padding-top: 0.6%;
}

.sub-module-dropdown-class {
  margin-left: -0.5rem;
}

.excluded-format {
  margin-left: 85%;
  margin-top: 25%;
  height: 25px;
  width: 42%;
  cursor: pointer;
}

.excluded-format-view {
  margin-left: 56%;
  cursor: pointer;
}

.overlay-access-control {
  width: 2rem !important;
}

.hr-class-role {
  margin: 0px !important;
}