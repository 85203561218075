/* Header.css */
.profile-photo {
    float: right;
  }

  .fs-16 {
    font-size: 16px !important;
  }
  
  @media (max-width: 768px) {
    .profile-photo {
      float: none; /* Reset float for mobile view */
      margin-right: 0;
      margin-bottom: 10px; /* Add some spacing between profile photo and text */
      display: block; /* Ensure the profile photo is displayed as a block element */
      margin: 0 auto; /* Center the profile photo in mobile view */
    }
  }