.container {
    background-color: #FFF;
    text-align: center;
    border-radius: 10px;
}

.banner-heading {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #121E2C;
    font-family: 'Montserrat', sans-serif;
}

.banner-sub-heading {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #50596B;
    font-family: 'Montserrat', sans-serif;
}

.btn-proceed-roles {
    display: inline-flex;
    width: 320px;
    height: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #07AC2E;
    color: #FFF;
    font-size: 16px;
    border: none;
    padding: 15px 20px;
  }

.btn-cancel-skip {
    display: inline-flex;
    height: 50px;
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    color: #50596B;
    font-size: 16px;
    font-weight: 500;
    background: none;
}

.btn-group{
    margin-top: 30px;
}

ul li {
    list-style-type: none;
    margin-right: 30px;
}

