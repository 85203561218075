.min-height-18 {
    min-height: 18vh;
}

.tabs-container {
    border: 1px solid rgba(242, 242, 244, 1);
    height: '18.5rem';
    border-radius: '4px';
}

.label-heading {
    font-weight: 600;
    font-size: 30px !important;
    line-height: 20px;
    color: #121E2C;
}

.section-heading {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #121E2C;
}

.ant-tabs-tab-active {
    color: gray; /* Change the text color */
    background-color: #f0f0f0; /* Change the background color */
    border-color: gray; /* Change the border color */
  }

.margin-bottom {
    margin-bottom: 0px;
}

.padding-20 {
    padding: 20px;
}


  