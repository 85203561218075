.limitText {
    float: right;
    color: #50596B;
    text-align: right;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
}

.dragTitle {
    color: #50596B;
    margin: 20px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}

.supportDcmnt {
    text-align: center;
    padding: 20px;
    border-radius: 6px;
    border: 2px dashed #F0F0F0;
    height: 336px;
}

.orIcon {
    margin: auto;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px dashed #E3E5E9;
}

.alertMsgs {
    color: var(--error, #DF6574);
    font-size: 13px;
    font-weight: 600;
    margin-left: 5px;
}

.wthoutSpcl {
    color: #50596B;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
}

textarea { height: auto !important; }

input[type=file] {
    display: none;
  }

.img-container {
    margin-top: 2rem;
    padding: 2px 40px;
    /* width: 49% !important; */
    max-height: 22.5rem;
    overflow: auto;
}