.countryCode {
    display: inline-flex;
    height: 46px;
    padding: 10px;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #F0F0F0;
    background: #DFDFE1;
    margin-right: 10px;
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
}

.agency-container-color {
    border-radius: 10px;
    background: #F8F8F8;
    padding: 16px 3px 16px;
}

.ml-61 {
    margin-left: 61px;
}

.click_head_1 {
    color: #50596B !important;
}

.custom-steps {
    width: 46% !important;
    /* Set the width of the steps container */
}

.custom-steps .ant-steps-item-title::after {
    margin-left: 45px !important;
}

.h-26 {
    height: 26px;
}

.wd-460 {
    width: 460px;
}