.btn-add-user {
    display: inline-flex;
    height: 42px;
    padding: 14px 30px 14px 30px;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #E3E5E9;
    background: #FFF;
    color: #50596B;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
}

.brdrtop td {
    color: #50596B;
    font-size: 14px;
    font-weight: 400;
}