.-mt-20 {
    margin-top: -1.5rem !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.custom-modal-spills-customize .modal-dialog {
    height: 23rem !important ;
    /* overflow-y: auto; */
  }

  .mt--20 {
    margin-top: -20px;
  }

  .line-width {
    margin-left: 12px;
    width: 54rem;
  }

  .custom-modal-spills-customize .modal-header {
border: none !important;
  }