.module-info-container {
  height: 6rem;
  display: flex;
  justify-content: space-between;
  background-image: linear-gradient(rgba(43, 79, 120, 1), rgba(11, 50, 95, 1));
  color: rgba(255, 255, 255, 1);
  /* padding: 20px 30px; */
  padding: 15px 30px;
  border-radius: 10px;
}

.module-info-container-width {
  width: 95%;
  margin-left: 2.5%;
}

.global-customize-container {
  display: flex;
  justify-content: space-between;
  color: rgba(18, 30, 44, 1);
  padding: 0px 16.5px;
  font-weight: 600;
  line-height: 26.82px;
  font-size: 22px;
}

.no_pad {
  padding: 0px !important;
}

textarea:focus,
input:focus {
  box-shadow: none !important;
  border-color: none !important;
}

.litico-desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}