.userDet {
    color: #121E2C;
    font-size: 20px;
    font-weight: 600;
}

.ml-15 {
    margin-left: 15px;
}

.frwrdImg {
    height: 35px;
}