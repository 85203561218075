.light-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px 20px;
}

.padding-left-35 {
  padding-left: 35px !important;
}

.container-div {
  display: flex;
  justify-content: space-between;
}
td.pl{
  padding-left: 48px;
}
.select-top {
  margin-top: 30px;
}

.info-icon-class {
  margin-left: 10px;
  margin-bottom: 5px;
}
