.plans {
    color: #50596B;
    font-size: 22px;
    font-weight: 500;
    opacity: 40%;
    border-bottom: 1px solid #F0F0F0;
}

.active-plan {
    color: #121E2C;
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    border-bottom: 3px solid #07AC2E;
}

.ml-20 {
    margin-left: 20px;
}

.mbnegative16 {
    margin-top: -16px;
}

.plan-name {
    color: #121E2C;
    font-size: 22px;
    font-weight: 600;
}

.plan-container {
    background-color: #FFF;
}


.change-plan-status {
    display: inline-flex;
    /* height: 36px; */
    height: 35px;
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #50596B;
    border-radius: 6px;
    border: 1px solid #E3E5E9;
}

.additional-features {
    color: #07AC2E;
    font-size: 18px;
    font-weight: 500;
}

.mt-10 {
    margin-top: 10px;
}

.feature-modal-head {
    color: #121E2C;
    font-size: 18px;
    font-weight: 600;
}

.more-feartures-modal {
    height: 250px !important;
    max-width: 888px !important;
    top: 45%;
    left: 35%;
    transform: translate(-50%, -50%) !important;
}

.wd-17 {
    width: 220px;
}

.single-plan-container {
    word-wrap: break-word;
}

.plan-label {
    line-height: 50px;
}

.edit-placement {
    margin-bottom: 5px;
    margin-left: 15px;
}