tr.hide-table-padding td {
  padding: 0;
}

tr {
  vertical-align: middle;
}

.expand-button {
  position: relative;
}

.accordion-toggle .expand-button:after {
  position: absolute;
  left: .75rem;
  top: 50%;
  transform: translate(0, -50%);
  content: '-';
}

.accordion-toggle.collapsed .expand-button:after {
  content: '+';
}

.full-cont {
  background-color: #F8F8FA;
}

.head_1 {
  font-size: 14px;
  color: #50596B;
}

.text-content {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #50596B;
}

.head_1 span {
  color: #121E2C;
  font-weight: 500;
}

.head_2 {
  font-size: 22px;
  color: #121E2C;
}

.head_3 {
  color: #121E2C;
  font-size: 14px;
  font-weight: 500;
}

.border_head_1 {
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.full-cont {
  /* padding: 3.3rem; */
  padding: 2.2rem;
}

.head_1 {
  font-weight: 400;
}

.inside_head,
.head_2 {
  font-weight: 600;
}

.inside_head,
.table {
  padding-top: 30px;
  padding-bottom: 30px;
}

.table-responsive {
  padding: 10px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 2px 16px 40px 0px rgba(112, 144, 176, 0.14);
}

thead {
  border-radius: 6px 6px 0px 0px;
  background: #F6F6F6;
}

th {
  color: rgba(0, 0, 0, 0.60) !important;
  border-radius: 6px 6px 0px 0px;
  background: #F6F6F6 !important;
  font-size: 14px;
  font-weight: 600;
}

td {
  color: #50596B;
  font-size: 14px;
  font-weight: 500;
}

.report_btn {
  /* max-width: 100%; */
  width: 164px;
  height: 50px;
  padding: 12px 20px;
  justify-content: center;
  border-radius: 6px;
  background: #07AC2E;
  font-size: 16px;
  color: #ffffff;
}

.report-btn-add-event-incident {
  height: 50px;
  padding: 12px 20px;
  justify-content: center;
  border-radius: 6px;
  background: #07AC2E;
  font-size: 16px;
  color: #ffffff;
}

.export_btn {
  /* display: flex; */
  /* height: 42px; */
  padding: 14px 14px 14px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  color: #50596B;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #E3E5E9;
  background-color: #FFF;
  ;
}

.text-right {
  text-align: right;
}

.editIcn {
  margin-right: 5px;
}

.filter_options {
  display: flex;
  justify-content: space-between;
  gap: 42px;
  padding: 14px 16px 14px 0px;
  align-items: center;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 2px 9px 20px 0px rgba(112, 144, 176, 0.07);
  margin-bottom: 10px;
}

.filter_options_1 {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding-left: 15px;
  align-items: center;
}

.clear_btn {
  display: flex;
  height: 42px;
  width: 124px;
  padding: 14px 0px 14px 16px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #E3E5E9;
  background: #FFF;
  color: #50596B;
  font-size: 14px;
  font-weight: 500;
}

.dropdown button {
  background: white;
  color: #50596B;
  border: none;
  font-size: 14px;
}

.ml-8 {
  margin-left: 5px;
}

.dropdown-toggle::after {
  content: none !important;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

.savedDraft {
  color: #D08228 !important;
  font-size: 14px;
  font-weight: 500;
}

.selected_btn {
  width: 348px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #07AC2E !important;
  background-color: #ffffff;
}

.eventInfo {
  color: #50596B;
  text-align: center;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin-top: 1rem !important;
}

.deselect_btn {
  width: 348px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #E3E5E9 !important;
  background-color: #ffffff;
}

.incidentInfo {
  color: #50596B;
  text-align: center;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin-top: 1rem !important;
  margin: auto;
  width: 685px;
}

.modal-dialog {
  max-width: 900px !important;
  height: 470px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 2px 16px 40px 0px rgba(112, 144, 176, 0.14);
}

.selected_btn img {
  margin-bottom: 2.2px;
  margin-right: 10px;
}

.deselect_btn img {
  margin-bottom: 2.2px;
  margin-right: 10px;
}

.incidentInfo img {
  margin-right: 5px;
}

.eventInfo img {
  margin-right: 5px;
}

.reportHead {
  color: #121E2C;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin: auto;
  width: 480px;
}

.report_head {
  color: #121E2C;
  font-size: 20px;
  font-weight: 500;
}

.modal-content,
.modal-footer {
  justify-content: center;
  border: none !important;
}

.btn-close-button {
  display: inline-flex;
  height: 50px !important;
  padding: 15px 20px !important;
  justify-content: center;
  align-items: center;
  --bs-btn-close-bg: none !important;
  /* color: #50596B; */
  font-size: 16px;
  font-weight: 500;
  margin-right: 30px !important;
  border: none;
  border-radius: 6px;
}

.btn-proceed {
  display: inline-flex !important;
  width: 147px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #07AC2E;
  color: #FFF;
  font-size: 16px;
  /* font-weight: 600; */
  border: none;
  padding: 15px 20px;
}

.close {
  border: none !important;
  background: none !important;
}

.dwnld:active {
  border-radius: 6px;
  background-color: #F2F2F4 !important;
  color: #2B3343 !important;
}

.infoHead {
  margin-top: -5px;
  margin-left: 5px;
}

.tooltip>div.tooltip-inner {
  background-color: white !important;
  color: black !important;
  display: flex;
  max-width: 806px;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 2px 16px 40px 0px rgba(112, 144, 176, 0.14), 2px 16px 40px 0px rgba(0, 0, 0, 0.20);
}

.tooltip.show {
  opacity: 1 !important;
}

.tooltip>div.arrow {
  /* border-bottom-color: white !important;
  color: white !important; */
  display: none;
}

.tooltip .tooltip-arrow {
  display: none !important;
}

.reportCat {
  color: #121E2C;
  font-size: 20px;
  font-weight: 500;
}

.headTolltip {
  color: #121E2C;
  font-size: 18px;
  font-weight: 600;
}

.descrpTolltip {
  color: #50596B;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

.incidentCollapse td {
  border-radius: 6px;
  border-top: 1px solid var(--outline, #F0F0F0);
  background: #FAFAFA !important;
  border: none;
}

.brdrtop td {
  border-top: 1px solid #F0F0F0 !important;
}

.filtrBy {
  font-weight: 600;
  margin-right: 15px;
  margin-top: -1.5px;
}

.form-check-input:checked {
  background-color: #07AC2E !important;
  border-color: #07AC2E !important;
}

.selectAll {
  color: #50596B;
  font-size: 16px;
  font-weight: 600;
}

.btn-clear {
  display: flex;
  height: 30px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid #E3E5E9 !important;
  color: #50596B !important;
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
  margin-right: 10px;
}

.btn-done {
  display: flex;
  height: 30px;
  padding: 10px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: linear-gradient(0deg, #07AC2E 0%, #07AC2E 100%), linear-gradient(180deg, #2B4F78 0%, #0B325F 100%) !important;
  color: #FFF !important;
  text-align: right;
  font-size: 16px;
  font-weight: 600;
}

.flex {
  display: flex;
}

.btn-proceed:hover {
  background: #07AC2E !important;
  color: #FFF !important;
  border-color: var(--bs-btn-hover-border-color);
}

.searchBox {
  display: flex;
  margin-left: 16px;
  width: 100%;
}

.searchBox img {
  margin-left: 4px;
}

.top-header {
  display: flex;
  justify-content: space-between;
}

hr.defaultLine {
  border: 0.5px solid rgba(0, 0, 0, 1);
}

.border-box {
   display: inline-block;
  border: 1px solid #F0F0F0;
  border-radius: 5px;
  padding: 5px;
  margin-right: 10px;
  /* margin-bottom: 5px;  */
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  max-width: 100%;
}

.showDateRange {
  height: 20px !important;
  width: 268px;
}

:where(.css-dev-only-do-not-override-1w4v3hc).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #07AC2E;
  color: #ffffff;
}

.datePickDown {
  margin-top: -20px;
}

.mt-mb-2 {
  margin-top: -10px;
  margin-bottom: -10px;
}

.no-data {
  font-size: 24px;
  font-weight: 500;
}

.pagination-body .disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.pagination-body {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.pagination-body .active {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #F0F0F0;
}

.pagination-body .page {
  color: #50596B;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.label-cover {
  display: flex;
  height: 43px;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid #F0F0F0;
}

.selected-filter-color {
  color: #07AC2E !important;
  /* cursor: pointer; */
}

.link-color {
  color: #07AC2E;
  cursor: pointer;
}

.notifier-list-style {
  left: 16%;
}

.float-right {
  float: right;
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  padding: 10px;
  background: #333;
  color: #fff;
  border: none;
  cursor: pointer;
}

.dropdown-button:hover {
 border: none;
}

.dropdown-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  /* border: 1px solid #ccc; */
  /* display: none; */
}

.dropdown-list li {
  padding: 10px;
  cursor: pointer;
}

.dropdown-list li:hover {
  background: #f0f0f0;
}

.dateRangeBtn {
  color: #50596B;
  font-size: 14px;
}

.spill_clear_filter {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding-left: 15px;
  align-items: center;
}

.spill_filter_options_1 {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  align-items: center;
}

.spill_filter_options {
  display: flex;
  justify-content: space-between;
  padding: 14px 16px 14px 0px;
  align-items: center;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 2px 9px 20px 0px rgba(112, 144, 176, 0.07);
  margin-bottom: 10px;
}

.spill-datepicker {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 70%;
}

