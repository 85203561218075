li {
    list-style-type: none;
}

.slide-up {
    margin-top: -23px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: #007bff; /* Change to your desired background color */
  }

.react-datepicker-wrapper {
    display: block !important;
}