.margin-0 {
    margin-top: 0px;
}

.padding-right-50 {
    padding-right: 50px;
}

.profile-cont {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.padding-top-8 {
    padding-top: 8px;
}

.contain-area {
    flex: 0 0 auto;
    width: 4.5%;
}

.mv-left {
    margin-left: -10rem;
}

.drp-prf-icn {
    margin-top: 13px !important;
    margin-left: -8px;
}

.fixed-height-of-container {
    /* max-height: 100vh;
    overflow-y: auto; */
}