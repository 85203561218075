.yearText {
    color: #121E2C;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
}

.imageBoxWidth {
    width: 450px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}

.imageBoxViewWidth {
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    margin-top: 10px;
}