.btn-upload-excel {
    display: inline-flex;
    height: 50px;
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid #E3E5E9;
    color: #50596B;
    font-size: 16px;
    font-weight: 500;
    background-color: #F8F8FA;
}

.btn-sample-format {
    display: inline-flex;
    height: 50px;
    padding: 15px 20px;
    background-color: #F8F8FA;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #50596B;
    font-size: 16px;
    font-weight: 500;
    border-radius: 6px;
}

.text-left {
    text-align: left;
}

.btn-cancel-popup {
    display: flex;
    height: 50px;
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid #E3E5E9;
    background-color: #ffffff;
}

.btn-upload {
    display: flex;
    width: 200px;
    height: 50px;
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #ffffff;
    border-radius: 6px;
    background: #07AC2E;
}

.mr-48 {
    margin-right: 48px;
}

.ln-50 {
    line-height: 50px;
}