.full-cont {
    /* padding: 3.3rem; */
    padding: 2.2rem;
}

.btn-update {
    display: inline-flex;
    width: 188px;
    height: 50px;
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: #07AC2E;
    color: #FFF;
    font-size: 16px;
    /* font-weight: 600; */
    border: none;
}

.btn-export {
    display: inline-flex;
    margin-top: 30px;
    border-radius: 6px;
    border: 1px solid #E3E5E9;
    color: #50596B;
    font-weight: 500;
    background: none !important;
    width: 188px;
    height: 50px;
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.img-preview {
    width: 100%;
    height: 100%;
    text-align: center;
    
}

.inputFieldHeadAddMore {
    color: #121E2C;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
}