/* Dashboard count box css */
.dashboard-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px; /* Adjust spacing between divs as needed */
    padding: 0px 32px;
  }
  
  .dashboard-item {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding: 15px 10px; /* Add content padding as needed */
    text-align: center;
    border-radius: 10px;
  }
  
  .dashboard-item img {
    width: 75px;
    height: 75px;
  }
  
  .dashboard-item span.direction {
    display: inline-block;
    width: 10px !important;
    height: 10px !important;
  }
  
  .dashboard-count-heading {
    text-align: left;
    padding-left: 25px !important;
  }
  
  .item-count {
    color: rgba(18, 30, 44, 1);
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    margin-top: 1.5rem;
  }
  
  .item-heading {
    color: rgba(18, 30, 44, 1);
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    height: 20px;
    width: 7.5rem;
  }
  
  .item-footer-font {
    color: rgba(80, 89, 107, 1);
    font-weight: 500;
    font-size: 14px;
  }
  
  .item-footer-font img {
    width: 25px;
    height: 25px;
  }

  