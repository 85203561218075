.light-container {
    background-color: #fff;
    border-radius: 10px;
    padding: 50px 20px;
}

.feature-wrapper {
    background-color: #DFDFE1;
    padding: 10px 20px;
    margin-right: 20px;
    border-radius: 20px;
}

.cross-btn-size {
    height: 8px;
    width: 8px;
    margin-left: 10px;
    cursor: pointer;
}

.pagination-body .disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  
  .pagination-body {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
  }
  
  .pagination-body .active {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 10px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #F0F0F0;
  }
  
  .pagination-body .page {
    color: #50596B;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
  }
  
  .label-cover {
    display: flex;
    height: 43px;
    padding: 10px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid #F0F0F0;
  }

  .ml-40 {
    margin-left: 35px;
  }

  .btn-add-roles-access {
    display: inline-flex;
    height: 50px;
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: #07AC2E;
    color: #FFF;
    font-size: 16px;
    border: 0;
  }