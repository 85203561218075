.min-height-18 {
    min-height: 18vh;
}

.tabs-container {
    border: 1px solid rgba(242, 242, 244, 1);
    height: '18.5rem';
    border-radius: '4px';
}

.label-heading {
    font-weight: 600;
    font-size: 30px !important;
    line-height: 20px;
    color: #121E2C;
}

.section-heading {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #121E2C;
}

.ant-tabs-tab-active {
    color: gray; /* Change the text color */
    background-color: #f0f0f0; /* Change the background color */
    border-color: gray; /* Change the border color */
  }

.margin-bottom {
    margin-bottom: 0px;
}

.margin-right-30 {
    margin-right: 30px;
}

.margin-right-25 {
    margin-right: 25px;
}

.yearly-sub-text {
    color: '#50596B';
    font-family: 'Montserrat';
    font-weight: 300;
}

.margin-bottom-5 {
    margin-bottom: -5px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #07AC2E !important; 
    font-weight: 500;
 } 

 .custom-tabs .ant-tabs-tab:hover {
    color: #07AC2E !important
  }
  

 .custom-tabs .ant-tabs-ink-bar {
    background: #07AC2E !important;
 }
 
 .customize-modal {
    max-width: 900px !important;
    height: 470px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 2px 16px 40px 0px rgba(112, 144, 176, 0.14);
}

.select-text div {
    color: #50596B;
    width: 168px;
    height: 41px;
    padding: 12px 12px 34px 12px;
    border-bottom: 1px solid #F2F2F4;
    cursor: pointer;
}

.current-select-text {
    background-color: #F2F2F4;
    border-radius: 6px;
}

.year-range {
    color: #121E2C;
    font-size: 18px;
    font-weight: 500;
}

.text-head-set {
    color: #121E2C;
    font-size: 16px;
    font-weight: 500;
}

.sub-text {
    color: #50596B;
    font-size: 12px;
}

.brd-top {
    border-top: 1px solid #F2F2F4;
}

.pdtp-12 {
    padding-left: 30px;
    padding-right: 30px;
}

.place-end {
    display: flex;
    margin-top: 5rem;
    justify-content: end !important;
}

.brd-cover {
    border: 1px solid #F2F2F4;
    border-top: none;
}