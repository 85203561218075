  /* overview chart styling */

  .horizontal-overview-container {
    display: flex; 
    justify-content: space-evenly;
     
  }

  .sub-graph-container {
    width: 80%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    margin-left: 10px;
  }

  .safety-near-miss-container {
    width: 65%;
    height: 20%;
    background-color: rgba(255, 255, 255, 1);
    margin: auto;
    border-radius: 10px;
    margin-top: 2rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding: 20px 20px;
  }

  .graph-heading {
    color: #121E2C;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    padding: 10px 20px;
  }