.light-container {
    background-color: #fff;
    border-radius: 10px;
    padding: 50px 20px;
}

.feature-wrapper {
    background-color: #DFDFE1;
    padding: 10px 20px;
    margin-right: 20px;
    border-radius: 20px;
    color: #000000;
    word-wrap: break-word;
}

.cross-btn-size {
    height: 8px;
    width: 8px;
    margin-left: 10px;
    cursor: pointer;
}

.line-h {
    line-height: 3rem;
}

.color-grey {
    color: #50596B !important;
    font-weight: 400 !important;
}