main {
  width: 100%;
  padding: 20px;
}

.sidebar {
  position: fixed;
  z-index: 1000;
  background-image: linear-gradient(rgba(23, 52, 84, 1), rgba(6, 30, 58, 1));
  color: rgba(172, 190, 210, 1);
  text-align: center;
  height: 100%;
  overflow: auto;
  transition: all 0.5s;
  /* height: 681px; */
  /* width: 110px; */
}

.close-sidebar {
  width: 110px;
}

.open-sidebar {
  width: 230px;
}

.top_section {
  text-align: center;
  /* display: flex;
    align-items:center; */
  padding: 20px 15px;
}

.litico-logo {
  font-size: 30px;
}

.litico-icon {
  text-align: center;
  font-size: 30px;
}

.litico-img {
  height: 25px;
}

.bars {
  text-align: right;
  /* display: flex; */
  font-size: 25px;
  margin-left: 50px;
}

.link {
  display: flex;
  text-decoration: none;
  color: rgba(172, 190, 210, 1);
  padding: 10px 40px;
  /* padding: 10px 53px; */
  gap: 10px;
  transition: all 0.5s;
}

.link:hover {
  cursor: pointer;
  /* background: rgba(6, 29, 55, 1); */
  /* color: #fff; */
  transition: all 0.5s;
}

.active-sidebar {
  /* background: rgba(26, 211, 147, 1); */
  color: rgba(26, 211, 147, 1);
  /* border-right: 10px solid rgba(26, 211, 147, 1); */
  position: relative;
}

.active-sidebar::after {
  content: '';
  position: absolute;
  width: 8px;
  /* height: 100%; */
  height: 36px;
  background-color: rgba(26, 211, 147, 1);
  right: 0%;
  transform: translate(0%, -50%);
  top: 50%;
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
}

.icon,
.link-text {
  /* font-size: 13px; */
  font-size: 12px;
}

.footer-text {
  margin-top: calc(40vh);
  font-size: 10px;
}

.link-sub-heading-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}

.sidebarStyle {
  background-color: #061D37;
  /* padding-top: 5px; */
}

.active-sub-menu {
  /* width: 230px; */
  height: 40px;
  flex-shrink: 0;
  border-radius: 8px;
  /* margin-left: 6px; */
  background: #0E2743;
  background-color: rgba(14, 39, 67, 1);
}

.img-width {
  width: 12px;
}

.icon img {
  width: 14px;
}

.ml-move {
  margin-top: -3px;
}